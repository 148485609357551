import "./App.css";

function App() {
  return (
    <div className="App">
      <header>
        <h1 className="header">FOTSO & CO LAW FIRM</h1>
      </header>
      <main className="main">
        <div className="img"></div>
        <h1>SITE EN CONSTRUCTION</h1>
        <h3>
          Notre site web sera disponible très bientôt, riche en qualité et avec
          des informations très intéressantes
        </h3>
      </main>
      <footer>
        <h3 className="footer">contact: fotsoleonnelle@fotsoandco.com </h3>
      </footer>
    </div>
  );
}

export default App;
